<template>
  <section>
    <b-row class="match-height">
      <b-col md="4" v-for="major in dataMajor" v-bind:key="major.id">
        <b-card class="card-app-design">
          <b-card-group deck>
            <b-card
            :bg-variant="isEven(major.id) ? 'success' : 'danger'"
              text-variant="light"
              class="text-center"
            >
            <b>{{ major.nama }}</b>
            </b-card>
          </b-card-group>
            <b-button variant="dark" block @click="addData(major.id)"> Pilih Jurusan Ini </b-button>
        </b-card>
      </b-col>

    </b-row>
  </section>
</template>

<script>
import {
  BCard,
  BAvatar,
  BImg,
  BCardText,
  BBadge,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BMediaBody,
} from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Swal from "sweetalert2";
import moment from "moment";
import "moment/locale/id";
export default {
  components: {
    BCard,
    BImg,
    ToastificationContent,
    Swal,
    BCardText,
    BBadge,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    BRow,
    BCol,
    StatisticCardHorizontal,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    AppTimeline,
    AppTimelineItem,
  },
  data() {
    return {
      dataMajor: [],
      level: localStorage.getItem("Ulevel"),
      id_session: localStorage.getItem("Uid"),
      getUrl: "",
    };
  },
  computed: {
  
  },
  async mounted() {
    this.checkAuth();
    await this.getMajor();
    this.getUrl = process.env.VUE_APP_URL;
  },
  methods: {
    checkAuth() {
      var sessionCheck = localStorage.getItem("Uid");
      if (sessionCheck == null || sessionCheck == "") {
        this.$router.push({ name: "login" });
      }
    },
    isEven(number) {
      return number % 2 === 0
    },
    async getMajor() {
      await this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "majories/view")
        .then((res) => {
          this.dataMajor = res.data.data;
        });
    },
    formatDate(value) {
      moment.locale("id");
      if (value != null) {
        const val = moment(value).format("ll");
        return val;
      }
    },
    addData(value){
      this.$router.push('/user/tryout/' +
      value )
    }
  },
};
</script>
